<template>
  <div>
    <p class="text-2xl font-bold">HR Dashboard</p>
    <div class="bg-white p-4 mt-4 rounded-lg shadow-md">
      <p class="font-bold">Attendance Record</p>
      <div class="flex items-center my-4">
        <div class="mr-4">
          <p class="text-sm my-2 text-gray-500">Start date</p>
          <TextField type="date" v-model="filter.startDate" borderEnabled placeholder="Select Date" />
        </div>
        <span class="mt-8">-</span>
        <div class="ml-4">
          <p class="text-sm my-2 text-gray-500">End date</p>
          <TextField type="date" v-model="filter.endDate" borderEnabled placeholder="Select Date" />
        </div>
        <div class="ml-4">
          <p class="text-sm my-2 text-gray-500">Group By</p>
          <select v-model="filter.groupBy" class="form-control m-input p-3 bg-white border-2 rounded-lg">
            <option value="" disabled>-- Pilih --</option>
            <option v-for="data in selectGroupByList" :key="data" :value="data" class="text-sm">
              {{ data }}
            </option>
          </select>
        </div>
        <div class="flex items-center">
          <button class="w-32 p-2 mx-3 mt-10 font-semibold inline bg-yellow text-white text-center rounded-lg" @click="fetchAttandancesSummary(filter)">Apply</button>
          <button class="p-2 bg-yellow-secondary mt-10 rounded-lg inline" @click="filter.reset(), fetchAttandancesSummary(filter)">
            <img class="w-7" src="../../assets/images/reload.png" alt="" /></button>
        </div>
      </div>
      <div v-if="chartBar ? chartBar.labels.length : false">
        <bar-chart :data="chartBar" :options="chartOptions" :width="800" :height="320"></bar-chart>
      </div>
      <div class="flex h-96 justify-center items-center" v-else>
        <p>Data not found</p>
      </div>
      <div class="flex flex-wrap justify-center">
        <div class="flex items-center m-4" v-for="(data, idx) in pieData" :key="idx">
          <div :style="`background: ${data.color}`" class="mr-2 w-5 h-5 rounded-sm"></div>
          <p>{{ data.title }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { showVueToast } from '@/utils'
import { mapActions } from 'vuex'
export default {
  name: 'dashboard-hr',
  data() {
    return {
      filter: {
        groupBy: 'WEEKLY',
        startDate: '',
        endDate: '',
        reset: () => {
          this.filter.startDate = ''
          this.filter.endDate = ''
          this.filter.groupBy = 'WEEKLY'
        }
      },
      selectGroupByList: ['DAILY', 'WEEKLY', 'MONTHLY', 'YEARLY'],
      pieData: null,
      chartBar: null,
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false
          }
        }
      }
    }
  },
  components: {
    BarChart: () => import('./BarChart.js'),
    Trash: () => import('@/components/Icons/Trash'),
    TextField: () => import(/* webpackChunkName: "TextField" */ '@/components/Form/TextField')
  },
  computed: {
    customerId() {
      return localStorage.getItem('client')
    }
  },
  methods: {
    ...mapActions('attendance', ['GET_LIST_ATTENDANCE_CHART']),
    ...mapActions('common', ['showLoading', 'hideLoading']),
    async fetchAttandancesSummary(params) {
      this.showLoading()
      try {
        const resp = await this.GET_LIST_ATTENDANCE_CHART({
          customerId: this.customerId,
          ...params
        })
        this.resetChart()
        resp.forEach((e) => {
          this.chartBar.labels.push(e.attendance_date_name)
          this.chartBar.datasets[0].data.push(e.total_completed)
          this.chartBar.datasets[1].data.push(e.total_start)
          this.chartBar.datasets[2].data.push(e.total_leave)
          this.chartBar.datasets[3].data.push(e.total_alpha)
        })
      } catch {
        // showVueToast('There is a problem retrieving data', 'error', 2000)
      }
      this.hideLoading()
    },
    resetChart() {
      this.chartBar = {
        labels: [],
        datasets: [
          {
            backgroundColor: '#3b82f6',
            data: []
          },
          {
            backgroundColor: '#00B3BE',
            data: []
          },
          {
            backgroundColor: '#A967AA',
            data: []
          },
          {
            backgroundColor: '#EB5757',
            data: []
          }
        ]
      }
      this.pieData = [
        {
          title: 'Total Start & Leave',
          color: '#3b82f6',
          count: 0
        },
        {
          title: 'Total Start',
          color: '#00B3BE',
          count: 0
        },
        {
          title: 'Total Leave',
          color: '#A967AA',
          count: 0
        },
        {
          title: 'Total Alpha',
          color: '#EB5757',
          count: 0
        }
      ]
    }
  },
  mounted() {
    this.fetchAttandancesSummary(this.filter)
  }
}
</script>
